.joke-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@import url('https://fonts.googleapis.com/css2?family=Kablammo&family=Noto+Sans+JP:wght@200;300&family=Open+Sans:wght@400;500&display=swap');

.title {
    margin-bottom: 16px;
    font-family: 'Kablammo', cursive;
    color: #902D6A !important;;
}

@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

.welcome {
    font-family: 'Kalam', cursive;
    font-size: 24px;
}

.dropdown-menu-font {
    font-family: 'Kalam', cursive !important;
}

.joke-controls {
    display: flex;
    justify-content: center;
    align-items: center;
}

.joke-button {
    background-color: #F9C429 !important;
    border-color: #F9C429 !important;
}

#dropdown-basic-button {
    background-color: #902D6A !important;
    color: white;
    border-color: #902D6A !important;
}