.jokeCard {
    width: 18rem;
    height: 24rem;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}

.joke-card-body {
    flex: 1 0 auto;
}

@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

.joke-text {
    font-family: 'Kalam', cursive !important;
    font-size: 16px;
}

.save-button {
    background-color: #4ECFD1 !important;
    border-color: #4ECFD1 !important;
    color: black !important;
}
  
  