@import url('https://fonts.googleapis.com/css2?family=Kablammo&family=Noto+Sans&family=Noto+Sans+JP:wght@200;300&family=Open+Sans:wght@400;500&display=swap');

* {
  font-family: 'Noto Sans', sans-serif;
}

.container {
  padding: 0px !important;
}

.App {
  background: transparent;
}

body {
  background: url('../public/imgs/laughbackground.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}

/* Mobile styles */
body {
  font-size: 16px;
}

/* Tablet styles */
@media (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

/* Desktop styles */
@media (min-width: 992px) {
  body {
    font-size: 20px;
  }
}

