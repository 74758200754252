.register-container{
    padding: 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
}

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@1,500&display=swap');

.register-font {
    font-family: 'Arimo', sans-serif !important;
}

.register-color {
    background-color: #F9C429 !important;
}

.submit-button {
    background-color: #F9C429 !important;
    border-color: #F9C429 !important;
    font-family: 'Arimo', sans-serif !important;
}

.register-form .show-hide-button:hover {
    background-color: #902D6A !important;
}